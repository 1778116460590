import * as React from "react";
import { OktaAuth } from "@okta/okta-auth-js";

interface SecurityProviderProps {
  children: React.ReactNode;
}

const SecurityProvider: React.FC<SecurityProviderProps> = ({ children }) => {
  const [isAuthenticated, setAuthenticated] = React.useState(false);

  React.useEffect(() => {
    try {
      (async () => {
        const authClient = new OktaAuth({
          issuer: process.env.GATSBY_OKTA_ISSUER,
          clientId: process.env.GATSBY_OKTA_CLIENT_ID,
          pkce: true
        });

        authClient.setOriginalUri(process.env.GATSBY_OKTA_REDIRECT_URI as string);

        if (authClient.isLoginRedirect()) {
          authClient.handleLoginRedirect();
        } else if (!(await authClient.isAuthenticated())) {
          authClient.signInWithRedirect();
        } else {
          setAuthenticated(true);
        }
      })();
    } catch (error) {
      console.error(
        `There was an error authenticating to ${process.env.GATSBY_OKTA_ISSUER}`,
        error
      );
    }
  });

  return isAuthenticated ? <>{children}</> : <></>;
};

export default SecurityProvider;
