import * as React from "react";
import { CssBaseline, Paper, ThemeProvider } from "@mui/material";
import { theme } from "./Theme";
import Footer from "./Footer";
import Grid from "@mui/material/Grid";
import Header from "./Header";
import Helmet from "react-helmet";
import SecurityProvider from "./SecurityProvider";
interface LayoutProps {
  children: React.ReactNode;
  title?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  const defaultTitle = "Tag Registry";
  const titleComponent = title ? `${title} - ${defaultTitle}` : defaultTitle;

  return (
    <>
      <Helmet>
        <title>{titleComponent}</title>
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid
          sx={{
            flex: 1,
            height: "100%"
          }}
          container
          direction="column"
        >
          <SecurityProvider>
            <Grid
              item
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Header />
            </Grid>
            <Grid item sx={{ marginBottom: "15%" }}>
              <Paper sx={{ padding: 5, height: "100%", boxShadow: "none" }}>{children}</Paper>
            </Grid>
            <Grid item>
              <Footer />
            </Grid>
          </SecurityProvider>
        </Grid>
      </ThemeProvider>
    </>
  );
};

export default Layout;
