import "../styles/ncss.css";
import { Theme, createTheme } from "@mui/material/styles";

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: "#000"
    },
    secondary: {
      main: "#FFF"
    },
    neutral: {
      main: "#CCC"
    },
    text: {
      secondary: "rgba(255,255,255,0.7)",
      disabled: "rgba(255,255,255,0.5)"
    },
    divider: "rgba(201,85,85,0.12)"
  },
  typography: {
    fontFamily: `"Helvetica-Neue-W31", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
    display1: {
      fontFamily: `"Helvetica-Neue-W31", "Helvetica Neue", "Helvetica", "Arial", "sans-serif"`,
      textTransform: "uppercase",
      fontWeight: "bold"
    },
    display2: {
      fontFamily: `"Nike-TG", "Helvetica Neue", "Helvetica, Arial", "sans-serif"`,
      fontSize: "35px",
      lineHeight: "34px",
      color: "#fa5400"
    },
    display3: {
      fontFamily: `"Nike Futura", "Helvetica Neue", Helvetica, Arial, sans-serif`,
      textTransform: "uppercase",
      fontSize: "18px",
      lineHeight: "34px"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: "Nike Futura";
          font-style: normal;
          font-weight: 400;
          src: url(https://www.nike.com/static/ncss/4.0/dotcom/fonts/Nike-Futura.woff2) format("woff2"),
            url(https://www.nike.com/static/ncss/4.0/dotcom/fonts/Nike-Futura.woff) format("woff"),
            url(https://www.nike.com/static/ncss/4.0/dotcom/fonts/Nike-Futura.ttf) format("truetype");
        }

        @font-face {
          font-family: "Nike-TG";
          font-style: normal;
          font-weight: 400;
          src: url(https://www.nike.com/static/ncss/4.0/dotcom/fonts/Nike-TG.woff2) format("woff2"),
            url(https://www.nike.com/static/ncss/4.0/dotcom/fonts/Nike-TG.woff) format("woff"),
            url(https://www.nike.com/static/ncss/4.0/dotcom/fonts/Nike-TG.ttf) format("truetype");
        }

        @font-face {
          font-family: "Helvetica-Neue-W31";
          font-style: normal;
          font-weight: 400;
          src: url(https://www.nike.com/static/ncss/4.0/dotcom/fonts/Helvetica-Neue-W31.woff) format("woff"),
            url(https://www.nike.com/static/ncss/4.0/dotcom/fonts/Helvetica-Neue-W31.ttf) format("truetype");
        }
      `
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          display1: "h1",
          display2: "h2"
        }
      }
    }
  }
});
