import * as React from "react";
import { CaretDown } from "@nike/nike-design-system-icons";
import { Grid, Link, MenuItem, MenuList, Paper, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Resources {
  name: string;
  link: string;
}

const resources: Resources[] = [
  {
    name: "INTAKE REQUEST",
    link:
      "https://confluence.nike.com/display/DAT/Data+Capture+Digital+Marketing+Intake+Request+Form"
  },
  {
    name: "CONFLUENCE",
    link:
      "https://confluence.nike.com/pages/viewpage.action?spaceKey=CDM&title=CDEA+Digital+Marketing"
  },
  {
    name: "SLACK",
    link: "https://slack.com/app_redirect?channel=C01CTPA1HDF"
  }
];

const useStyles = makeStyles(() => ({
  caret: {
    position: "relative",
    top: 5
  }
}));

const ResourcesList: React.FC = () => {
  const [hovered, setHovered] = React.useState(false);
  const classes = useStyles();

  return (
    <Grid
      item
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      sx={{
        padding: "5px 14px",
        "&:hover": {
          backgroundColor: "#CCC"
        }
      }}
    >
      <Grid container direction="row">
        <Typography
          align="center"
          variant="display2"
          color="black"
          sx={{
            fontSize: "20px",
            letterSpacing: "-1px"
          }}
        >
          CONNECT WITH US
        </Typography>
        <CaretDown size="s" className={classes.caret} />
        {hovered && (
          <Stack
            direction="row"
            spacing={2}
            sx={{
              position: "absolute",
              padding: 0
            }}
          >
            <Paper
              sx={{
                zIndex: 2,
                position: "relative",
                top: "40px",
                right: "14px"
              }}
            >
              <MenuList
                sx={{
                  padding: 0
                }}
              >
                {resources.map((resource, index) => (
                  <MenuItem key={index}>
                    <Typography
                      align="center"
                      variant="display2"
                      color="black"
                      sx={{
                        fontSize: "20px",
                        letterSpacing: "-1px"
                      }}
                    >
                      <Link
                        href={resource.link}
                        rel="noopener noreferrer"
                        target="_blank"
                        color="primary"
                        underline="none"
                      >
                        {resource.name}
                      </Link>
                    </Typography>
                  </MenuItem>
                ))}
              </MenuList>
            </Paper>
          </Stack>
        )}
      </Grid>
    </Grid>
  );
};

export default ResourcesList;
