import * as React from "react";
import { AppBar, Grid, Hidden, Link, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";

const Footer: React.FC = () => (
  <AppBar color="primary" sx={{ padding: "16px 16px", top: "auto", bottom: 0 }}>
    <Grid container direction="row" sx={{ justifyContent: "space-between" }}>
      <Hidden smDown>
        <Grid item sm={2}>
          <StaticImage
            style={{
              maxWidth: "200px",
              minWidth: "200px"
            }}
            src="../images/cdea-dm-logo-white.png"
            alt="cdea-dm-logo"
          />
        </Grid>
      </Hidden>
      <Grid item sm={6} sx={{ display: "flex", justifyContent: "end" }}>
        <Grid container direction="column">
          <Grid item sx={{ display: "flex", justifyContent: "end" }}>
            <Typography variant="display3" color="#FA5400">
              CDEA Digital Marketing // TAG REGISTRY
            </Typography>
          </Grid>
          <Grid item sm={6} sx={{ display: "flex", justifyContent: "end" }}>
            <Typography variant="display3">
              <Link
                href="https://confluence.nike.com/display/DAT/Data+Capture+Digital+Marketing+Intake+Request+Form"
                rel="noopener noreferrer"
                target="_blank"
                color="secondary"
              >
                ENGAGE WITH US
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </AppBar>
);

export default Footer;
