import * as React from "react";
import { AppBar, Grid, Link, Typography } from "@mui/material";
import { NikeApp } from "@nike/nike-design-system-icons";
import { navigate } from "gatsby";
import ResourcesList from "./ResourcesList";

const Header: React.FC = () => (
  <AppBar
    position="static"
    color="secondary"
    sx={{
      boxShadow: 0,
      borderRadius: 0
    }}
  >
    <Grid container direction="column" justifyContent="center" spacing={2}>
      <Grid
        item
        xs={3}
        sx={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <NikeApp width="85px" height="85px" />
      </Grid>
      <Grid container direction="row" justifyContent="center">
        <Grid
          item
          sx={{
            padding: "5px 14px",
            "&:hover": {
              backgroundColor: "#CCC"
            }
          }}
        >
          <Typography
            align="center"
            variant="display2"
            color="black"
            sx={{
              fontSize: "20px",
              letterSpacing: "-1px"
            }}
          >
            <Link
              href={"https://eda.nike.com/Home/eda-home-1"}
              rel="noopener noreferrer"
              target="_blank"
              color="primary"
              underline="none"
            >
              HOME
            </Link>
          </Typography>
        </Grid>
        <Grid
          item
          sx={{
            padding: "5px 14px",
            "&:hover": {
              backgroundColor: "#CCC"
            }
          }}
        >
          <Typography
            align="center"
            variant="display2"
            color="black"
            onClick={() => navigate("/tag")}
            sx={{
              fontSize: "20px",
              letterSpacing: "-1px"
            }}
          >
            TAG REGISTRY
          </Typography>
        </Grid>
        <ResourcesList />
      </Grid>
    </Grid>
  </AppBar>
);

export default Header;
